import PageModule from './PageModule';
import component from '../../containers/checkout/CheckoutSummary';

/**
 * The module is displayed on the Checkout Success page
 * and shows an overview of the purchased products.
 * @see https://confluence.db-n.com/x/EKbc
 */
class CheckoutSummaryModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default CheckoutSummaryModule;
