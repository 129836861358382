import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { showDialog } from '../../../actions/page/dialog';
import { capitalize } from '../../../helpers/str';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import Copy from '../../basics/text/TextCopy';
import Headline from '../../basics/text/TextHeadline';

const componentName = 'FriendReferralStatusCard';
function FriendReferralStatusCard(props) {
  const {
    utilities,
    dispatch,
    theme,
    className,
    statusItem,
    showHistoryDialog,
  } = props;

  return (
    <div
      className={suitcss({
        className,
        componentName,
        utilities: [utilities],
        modifiers: [theme && `theme${capitalize(theme)}`],
      })}
    >
      {statusItem.statusItemIcon && (
        <span
          className={suitcss({
            componentName,
            descendantName: 'icon',
          })}
        >
          <SvgLoader path={statusItem.statusItemIcon} />
        </span>
      )}
      <div className={suitcss({ componentName, descendantName: 'content' })}>
        {statusItem.statusItemHeadline && (
          <Headline size="xxs" embedded utilities={['weightBold']}>
            {statusItem.statusItemHeadline}
          </Headline>
        )}
        {statusItem.statusItemSubline && (
          <Copy
            size="secondary"
            embedded
            element="div"
          >
            <span className={statusItem.statusItemColorUtility}>
              {statusItem.statusItemSubline}
            </span>
            {statusItem.statusItemDialog && (
              <div
                className={suitcss(
                  {
                    descendantName: 'info',
                    modifiers: [statusItem.statusItemStatus === 'ACTIVE' ? 'success' : 'pending'],
                  },
                  this,
                )}
                onClick={() =>
                  dispatch(showDialog(
                    {
                      title: 'info',
                      copy: statusItem.statusItemDialog.copy,
                      withCloseAction: false,
                      actions: [{
                        label: statusItem.statusItemDialog.actionLabel,
                        withoutArrow: true,
                        ...(showHistoryDialog && {
                          action: () => showHistoryDialog(),
                        }),
                      }],
                    }))
                }
              >
                <SvgLoader path="/icons/content-info.svg" />
              </div>
            )}
          </Copy>
        )}
        {statusItem.statusItemCopy && (
          <Copy size="secondary" embedded utilities={['marginTopXXS']} raw>
            {statusItem.statusItemCopy}
          </Copy>
        )}
      </div>
    </div>
  );
}

FriendReferralStatusCard.propTypes = {
  utilities: PropTypes.array,
  className: PropTypes.string,
  theme: PropTypes.string,
  dispatch: PropTypes.func,
  statusItem: PropTypes.object.isRequired,
  showHistoryDialog: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(connect(mapDispatchToProps))(FriendReferralStatusCard);
