import initForm from '../form/FormInitializer';
import ContactFormComposition from '../../components/compositions/contact/ContactForm';
import { trackMyState } from '../../actions/tracking/event';
import { CONTACT_FORM_TYPE_GDPR, MYTRACK_CONTACT_SUBMIT_SUCCESS } from '../../helpers/constants';
import FormConfigProps from '../../model/form/FormConfigProps';

const createFieldMap = (state, { params, ui, formValues }) => ({
  customer: {
    name: 'customer',
    label: ui.guiFormCustomer,
    validation: {
      isRequired: params.type === CONTACT_FORM_TYPE_GDPR,
    },
    options: [
      {
        label: ui.guiFormGdprCustomerYes,
        value: '1',
      },
      {
        label: ui.guiFormGdprCustomerNo,
        value: '0',
      },
    ],
  },
  subject: {
    name: 'subject',
    label: ui.guiFormSubject,
    options: params.subjects.map(subject => ({
      label: subject.name,
      options: subject.options.map(option => ({
        label: option,
        value: option,
      })),
    })),
    validation: {
      isRequired: true,
    },
  },
  salutation: {
    name: 'salutation',
    label: ui.guiFormSalutation,
    validation: {
      isRequired: true,
    },
    options: [
      {
        label: ui.guiFormSalutationFemale,
        value: '02',
      },
      {
        label: ui.guiFormSalutationMale,
        value: '01',
      },
    ],
  },
  firstName: {
    name: 'first_name',
    label: ui.guiFormNameFirst,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  lastName: {
    name: 'last_name',
    label: ui.guiFormNameLast,
    validation: {
      isRequired: true,
      range: [null, 50],
    },
  },
  email: {
    name: 'contact_email',
    label: ui.guiFormEmail,
    hint: ui.guiFormEmailHint,
    type: 'email',
    validation: {
      isRequired: true,
      pattern: 'email',
    },
  },
  phone: {
    name: 'contact_phone',
    label: formValues.customer
      ? Number(formValues.customer) ? ui.guiFormGdprCustomerMsisdn : ui.guiFormPhone
      : ui.guiFormPhone,
    hint: ui.guiFormPhoneHint,
    type: 'tel',
    validation: {
      pattern: 'phone',
      range: [7, 25],
      isRequired: !Number(formValues.customer),
      dependsOn: {
        customer: [
          '1',
        ],
      },
    },
  },
  message: {
    name: 'message',
    label: ui.guiFormMessageHint,
    validation: {
      isRequired: true,
    },
  },
  customerId: {
    name: 'customer_id',
    label: ui.guiFormCustomerId,
    hint: ui.guiFormCustomerIdHint,
    validation: {
      range: [0, 30],
    },
  },
  captcha: {
    name: 'captcha',
    label: 'captchaLabel',
    type: 'captcha',
    validation: {
      range: [3, 3],
    },
  },
});

// init component once, outside
const component = initForm()(ContactFormComposition);

export const id = 'data';

const makeSubmit = () => async (values, dispatch) => {
  dispatch(trackMyState(MYTRACK_CONTACT_SUBMIT_SUCCESS));
};

/**
 * @return {FormConfig}
 */
export const mapStateToFormConfig = (state, props) => ({
  id,
  component,
  fieldMap: createFieldMap(state, props),
  makeSubmit,
  formConfigProps: new FormConfigProps({
    labelSubmit: state.ui.guiFormSubmit,
  }),
});

export default mapStateToFormConfig;
