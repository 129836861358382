import { push } from 'react-router-redux';
import * as reduxForm from 'redux-form';
import {
  addExtensiveDialogToQueue,
  hideDialog,
  hideQueuedDialog,
  showDialog,
  showNotification,
  showQueuedDialogOnce,
} from '../page/dialog';
import { trackMyState } from '../tracking/event';
import { blockViewport } from '../site/viewport';
import SimpleLoginForm from '../../containers/form/SimpleLoginForm';
import {
  REQUEST_METHOD_PATCH,
  TRACK_REPLACE_AVATAR_ID,
  MYTRACK_MYDATA_AVATAR_DIALOG,
  MYTRACK_MYDATA_AVATAR_DIALOG_CANCEL,
  MYTRACK_MYDATA_AVATAR_DIALOG_SUBMIT,
  MYTRACK_MYDATA_AVATAR_CHANGE_IMAGE,
  DIALOG_TYPE_CUSTOM,
  REQUEST_METHOD_POST,
  STORAGE_KEY_EMAIL,
  FORM_FIELD_GDPR_CONSENT_DAT,
  FORM_FIELD_GDPR_CONSENT_BEW,
  CONSENT_INQUIRE_DATA_TYPE_DATA,
  CONSENT_INQUIRE_DATA_TYPE_BEW,
  UI_ESIM_DEVICE_CHECK,
} from '../../helpers/constants';
import { getWebEntityName } from '../../helpers/entity';
import { toDecimal } from '../../helpers/money';
import ReauthRequest from '../../model/requests/ReauthRequest';
import CustomerDataRequest from '../../model/requests/CustomerDataRequest';
import GdprConsentsRequest from '../../model/requests/GdprConsentsRequest';
import { send, sendIfNecessary } from '../request/send';
import * as registryActions from '../request/registry';
import { clearCart, removeItem, replaceCart } from '../order/cart';
import MyAvatarSelector from '../../components/compositions/account/MyAvatarSelector';
import OptionLayerDialog from '../../components/compositions/dialog/OptionLayerDialog';
import GiveMeYourPriceChatDialog from '../../components/compositions/dialog/GiveMeYourPriceChatDialog';
import CustomerServiceDialog from '../../components/compositions/dialog/CustomerServiceDialog';
import HotlineOptionsDialog from '../../components/compositions/dialog/HotlineOptionsDialog';
import FriendReferralHistoryDialog
  from '../../components/compositions/dialog/FriendReferralHistoryDialog';
import PromotionCodeForm from '../../containers/promotion/PromotionCodeForm';
import { bindQueryParams, locationToUrl } from '../../helpers/url';
import {
  ACCOUNT_USER_CUSTOMER_DATA,
  ACCOUNT_USER_GDPR_CONSENTS_INQUIRY,
  clearUserData,
} from '../user/clear';
import EmailValidationRequest from '../../model/requests/EmailValidationRequest';
import AdConsentUpdateForm from '../../containers/account/AdConsentUpdateForm';
import ConsentInquiryDialog from '../../components/compositions/dialog/ConsentInquiryDialog';
import QueueableRequest from '../../model/requests/QueueableRequest';
import ESimDeviceCheck from '../../containers/eSim/ESimDeviceCheck';

// @todo login aborted error; has nothing todo with contract renewal
const contractRenewalAbortedError = new Error('Contract renewal was aborted');

export const showSimpleDialog =
  (headline, copy, label) => dispatch => {
    const actions = [
      {
        label,
        withoutArrow: true,
        action: () => {
          dispatch(hideDialog());
        },
      },
    ];
    dispatch(showDialog({
      headline,
      copy,
      actions,
    }));
  };

/**
 * Triggers a dialog with cancel button (closes the dialog) and action button,
 * callback is executed when the action button is clicked.
 *
 * @param {string} headline
 * @param {string} copy
 * @param {string} labelAction
 * @param {string} labelCancel
 * @param {function} callback
 */
export const showSimpleDialogWithCancelButtonAndAction =
  (headline, copy, labelAction, labelCancel, callback) => (dispatch) => {
    const actions = [
      {
        label: labelAction,
        action: () => {
          dispatch(hideDialog());
          callback();
        },
      },
      {
        label: labelCancel,
        asLink: true,
      },
    ];

    dispatch(showDialog({
      headline,
      copy,
      actions,
      withCloseAction: true,
    }));
  };

/**
 * Shows the promotion code form where the user can enter a promo code.
 *
 * Once the promo code has been entered, new promos are fetched and
 * the dialog is automatically closed if a promotion with a promo code
 * has been received.
 */
export const showPromoCodeDialog = () => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(showDialog({
    headline: ui.promotionCodeSubmit,
    theme: 'light',
    component: PromotionCodeForm,
    props: {
      ui,
      onAfterSubmit: async (promoCode, isValid) => {
        if (isValid) {
          dispatch(hideDialog());
        }
      },
      isFormVisible: true,
      asDialog: true,
    },
  }));
};

export const showCartEmptyDialog = (id) => (dispatch, getState) => {
  const { ui } = getState();
  const actions = [
    {
      label: ui.scaRemoveTariffDialogCta,
      action: () => {
        dispatch(hideDialog());
        dispatch(removeItem(id));
        dispatch(clearCart());
      },
    },
    {
      label: ui.guiWordCancel,
    },
  ];
  dispatch(showDialog({
    headline: ui.scaRemoveTariffDialogTitle,
    copy: ui.scaRemoveTariffDialogCopy,
    actions,
    withCloseAction: true,
    isExpanded: true,
  }));
};

/**
 * Opens up a dialog that contains a minimalistic login form.
 *
 * Note: This dialog action returns a promise so we can wait for
 * the user to login. In case the user exits the dialog, the promise
 * is rejected.
 *
 * @return {Promise} the full response returned from the login endpoint.
 */
export const showLoginDialog = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const onLoginSuccess = submitResult => resolve(submitResult);
    dispatch(showDialog({
      theme: 'medium',
      component: SimpleLoginForm,
      props: { onLoginSuccess },
      onClose: () => reject(contractRenewalAbortedError),
    }));
  });

/**
 * Will return a promise that resolves if credentials are either received via
 * reauthentication or login.
 *
 * Note: Credentials are also automatically updated in the store.
 *
 * @return {Promise} the updated user crentials.
 */
export const obtainCredentials = () => async (dispatch, getState) => {
  const { user } = getState();
  const { msisdn } = user.credentials;
  if (msisdn) {
    try {
      await dispatch(send(new ReauthRequest(msisdn)));
    } catch (e) {
      await dispatch(showLoginDialog());
    }
  } else {
    await dispatch(showLoginDialog());
  }
  dispatch(hideDialog());
  // freshly load user credentials from store
  return getState().user.credentials;
};

/**
 * dialog imforms the user that they're mmo card is no longer valid and that they can
 * exchange it for an available upgrade.
 */

export const showMmoSimActivationTransferDialog = (simStatusResponse) => async (
  dispatch, getState,
) => {
  const { ui, site } = getState();
  if (!simStatusResponse.freeSimExchangeAvailable || !simStatusResponse.tariff) {
    return;
  }

  const tariffVE = await dispatch(
    registryActions.fetchEntityByInternalId(simStatusResponse.tariff.id),
  );

  const creditBalance = simStatusResponse.creditBalance ?
    `${toDecimal(simStatusResponse.creditBalance)} ${ui.guiSymbolEuro}` : '';

  const copy = ui.txtVididentActivationNotPossibleCopy
    .replace('{FREE_CARD}', `<b>${getWebEntityName(tariffVE)}</b>`)
    .replace('{PRICE}', `<b>${creditBalance}</b>`);

  const actions = [
    {
      label: ui.guiWordContinue,
      withoutArrow: true,
      action: () => {
        dispatch(replaceCart([tariffVE]));
        dispatch(push(site.sitemap.ShoppingCartRoute.url));

        dispatch(hideDialog());
      },
    },
    {
      label: ui.guiWordCancel,
      asLink: true,
      action: () => {
        dispatch(hideDialog());
      },
    },
  ];

  dispatch(showDialog({
    headline: ui.vididentActivationNotPossibleHeadline,
    copy,
    onClose: () => dispatch(hideDialog()),
    actions,
    withCloseAction: true,
  }));
};

/**
 * Shows a dialog which informs the user that the activation process is in progress and
 * that the process will be terminated / replace if they decide to continue.
 *
 * @return {Promise} true = reset and continue activation process, false = cancel and close
 * dialog.
 */
export const showSimActivationInProgressDialog = () => (dispatch, getState) =>
  new Promise((resolve) => {
    const { ui } = getState();
    const actions = [
      {
        label: ui.vididentActivationRepeat,
        withoutArrow: true,
        action: () => {
          resolve(true);
          dispatch(hideDialog());
        },
      },
      {
        label: ui.vididentActivationCancel,
        asLink: true,
        action: () => {
          resolve(false);
          dispatch(hideDialog());
        },
      },
    ];

    dispatch(showDialog({
      headline: ui.vididentActivationAlreadyActivatedHeadline,
      copy: ui.txtVididentActivationAlreadyActivatedCopy,
      onClose: () => {
        resolve(false);
        dispatch(hideDialog());
      },
      actions,
      withCloseAction: true,
    }));
  });


export const showAvatarSelectorDialog = () => async (dispatch, getState) => {
  const items = await dispatch(registryActions.fetchAvatars({ isBlocking: true }));
  const { user, ui } = getState();
  const { avatar: selectedItem, credentials: { msisdn } } = user;
  const selectedItemId = selectedItem ? selectedItem.id : null;
  let currentItemId = selectedItemId;
  const onSelect = (id) => {
    const msg = MYTRACK_MYDATA_AVATAR_CHANGE_IMAGE.replace(TRACK_REPLACE_AVATAR_ID, id);
    dispatch(trackMyState(msg));
    currentItemId = id;
  };
  const dialogConfig = {
    theme: 'light',
    component: MyAvatarSelector,
    props: { selectedItem, items, onSelect, ui },
    withCloseAction: true,
    actions: [
      {
        label: ui.guiWordSave,
        withoutArrow: true,
        expanded: true,
        action: async () => {
          if (!currentItemId || currentItemId === selectedItemId) {
            return;
          }
          const request = new CustomerDataRequest(msisdn, {
            payload: { avatarId: currentItemId },
            method: REQUEST_METHOD_PATCH,
          });
          await dispatch(send(request));
          dispatch(trackMyState(MYTRACK_MYDATA_AVATAR_DIALOG_SUBMIT));
          dispatch(hideDialog());
          dispatch(showNotification(ui.guiSettingsSaved));
        },
      },
    ],
    onClose: () => dispatch(trackMyState(MYTRACK_MYDATA_AVATAR_DIALOG_CANCEL)),
  };
  dispatch(trackMyState(MYTRACK_MYDATA_AVATAR_DIALOG));
  dispatch(showDialog(dialogConfig));
};

export const showGiveMeYourPriceChatDialog = (user, chatBot) =>
  (dispatch, getState) => {
    const { ui } = getState();
    const dialogConfig = {
      type: DIALOG_TYPE_CUSTOM,
      component: GiveMeYourPriceChatDialog,
      props: {
        user,
        chatBot,
        dispatch,
        actions: [{
          withoutArrow: true,
          label: ui.guiWordBack,
          action: () => {
            dispatch(hideDialog());
          },
        }],
      },
      withCloseAction: false,
      shouldCloseOnTransition: true,
    };
    dispatch(blockViewport(true, true));
    dispatch(showDialog(dialogConfig));
  };


export const showHotlineOptionsDialog = () => (dispatch, getState) => {
  const { ui } = getState();
  dispatch(showDialog({
    type: DIALOG_TYPE_CUSTOM,
    component: HotlineOptionsDialog,
    withCloseAction: false,
    props: {
      alignH: 'right',
      alignV: 'bottom',
      ui,
      label: 'Hotline Options Dialog',
      actions: [
        {
          action: async () => {
            // eslint-disable-next-line no-use-before-define
            dispatch(showCustomerServiceDialog());
          },
        },
      ],
    },
  }));
};

export const showCustomerServiceDialog = () => (dispatch, getState) => {
  const { ui, site } = getState();
  /* const baseTrackingPixel = {
    contact_action: 'engage',
    contact_subject: 'contact support',
    contact_tool: 'contact options',
    contact_trigger: 'button',
  }; */

  dispatch(showDialog({
    type: DIALOG_TYPE_CUSTOM,
    component: CustomerServiceDialog,
    withoutLightboxFadeout: true,
    isAutomaticallyTracked: true,
    props: {
      items: [{
        headline: ui.fcbChatHeadline,
        buttonText: ui.fcbChatLabel,
        isChatLauncher: true,
        actions: [
          {
            action: () => {
              dispatch(hideDialog());
              if (chat) { // eslint-disable-line no-undef
                chat.toggle(); // eslint-disable-line no-undef
                // dispatch(trackContact({ ...baseTrackingPixel, ...{ contact_tool: 'chat' } }));
              } else {
                console.error('Chat-Client not Initialized');
              }
            },
          },
        ],
      },
      {
        headline: ui.fcbContactHeadline,
        buttonText: ui.fcbContactLabel,
        actions: [
          {
            action: () => {
              dispatch(hideDialog());
              // dispatch(trackContact(baseTrackingPixel));
              dispatch(push('/service/kontakt'));
            },
          },
        ],
      },
      {
        headline: ui.fcbHotlineHeadline,
        buttonText: ui.fcbHotlineLabel,
        actions: [
          {
            action: async () => {
              dispatch(showHotlineOptionsDialog());
            },
          },
          {
            action: () => {
              // dispatch(trackContact(baseTrackingPixel));
              dispatch(hideDialog());
            },
          },
        ],
      },
      {
        headline: ui.fcbFaqHeadline,
        buttonText: ui.fcbFaqLabel,
        actions: [
          {
            action: () => {
              //
              // dispatch(trackContact(
              // { ...baseTrackingPixel, ...{ contact_subject: 'contact' }
              // }));
              dispatch(hideDialog());
              dispatch(push(site.sitemap.FaqRoute.url));
            },
          },
        ],
      }],
      actions: [
        {
          action: () => {
            dispatch(hideDialog());
          },
        },
      ],
      alignH: 'right',
      alignV: 'bottom',
    },
  }));
};

export const showTariffOptionTeaserDialog = params => async (dispatch) => {
  const { headline, copy, image, continueText, cancelText, onClose, onContinue } = params;
  const callFunctionAndHide = func => () => {
    func();
    dispatch(hideDialog());
  };

  const dialogConfig = {
    type: DIALOG_TYPE_CUSTOM,
    component: OptionLayerDialog,
    props: {
      headline,
      copy,
      image,
      continueText,
      cancelText,
      onClose: callFunctionAndHide(onClose),
      onContinue: callFunctionAndHide(onContinue),
    },
  };
  dispatch(showDialog(dialogConfig));
};

export const showEmailValidationDialog = () => async (dispatch, getState) => {
  const { ui, user, site } = getState();
  const { customerData } = user;

  const dialogConfig = {
    theme: 'light',
    type: 'type',
  };

  if (customerData.email) {

    switch (customerData.status) {
      case 'pending':
        dialogConfig.copy = ui.gdprEmailDialogPendingCopy;
        dialogConfig.headline = ui.gdprEmailDialogPendingTitle;
        break;
      case 'required':
        dialogConfig.copy = ui.gdprEmailDialogValidateCopy;
        dialogConfig.headline = ui.gdprEmailDialogValidateTitle;
        break;
    }

    dialogConfig.actions = [
      {
        label: customerData.status === 'pending' ? ui.guiWordContinue : ui.gdprEmailDialogValidateAction,
        withoutArrow: true,
        action: () => {
          if (customerData.status === 'required') {
            dispatch(send(new EmailValidationRequest(customerData.msisdn, {
              method: REQUEST_METHOD_POST,
            })));
            dispatch(showNotification(ui.gdprEmailValidationHint));
          } else {
            dispatch(push(`${bindQueryParams(site.sitemap.MyDataRoute.url, { edit: false })}#${ACCOUNT_USER_CUSTOMER_DATA}`));
          }
          dispatch(hideQueuedDialog());
        },
      },
      {
        label: ui.gdprEmailDialogValidateLater,
        withoutArrow: true,
      },
    ];
  } else {
    dialogConfig.copy = ui.gdprEmailDialogMissingCopy;
    dialogConfig.headline = ui.gdprEmailDialogMissingTitle;
    dialogConfig.actions = [{
      label: ui.gdprEmailDialogMissingAction,
      action: () => {
        dispatch(push(`${bindQueryParams(site.sitemap.MyDataRoute.url, { edit: true })}#${ACCOUNT_USER_CUSTOMER_DATA}`));
        dispatch(hideQueuedDialog());
      },
    }, {
      label: ui.gdprEmailDialogValidateLater,
    }];
  }
  dispatch(showQueuedDialogOnce(STORAGE_KEY_EMAIL, dialogConfig));
};


export const getAddConsentDialog = (entity, requestedLocation) => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new GdprConsentsRequest()));
  await dispatch(registryActions.fetchGdprConsentsAggregatedData());
  const { user, ui, routing } = getState();
  const isEntityConsentRequired = entity && entity.adConsent;
  const isUserConsentRequired = !!user.adConsent
    && !user.adConsent.blacklisted
    && (!user.adConsent.adConsent || !user.adConsent.passData);
  const shouldRender = (isEntityConsentRequired && isUserConsentRequired)
    || (!entity && isUserConsentRequired);

  if (!shouldRender) {
    return null;
  }

  const onClose = () => dispatch(push(locationToUrl(routing.locationBeforeTransitions)));

  return {
    component: AdConsentUpdateForm,
    props: {
      adConsent: user.adConsent,
      entity,
      ui,
      onSubmit: () => {
        dispatch(hideDialog());
        dispatch(push(locationToUrl(requestedLocation)));
      },
    },
    shouldCloseOnTransition: true,
    onClose,
    actions: [
      {
        label: ui.guiWordContinue,
        action: async () => {
          await dispatch(reduxForm.submit(AdConsentUpdateForm.formName));
        },
      },
      {
        label: ui.guiWordCancel,
        asLink: true,
        action: onClose,
      },
    ],

  };
};

export const getConsentInquiryDialog = () => async (dispatch, getState) => {
  await dispatch(sendIfNecessary(new GdprConsentsRequest()));
  await dispatch(registryActions.fetchGdprConsentsAggregatedData({
    priority: QueueableRequest.PRIO_80,
  }));
  const { user, site, ui } = getState();
  const { gdprConsentsInquiry, adConsent } = user;
  const { consentTexts } = site;

  const showConsent = !!gdprConsentsInquiry && adConsent && !adConsent.blacklisted &&
    (gdprConsentsInquiry.inquirePassData || gdprConsentsInquiry.inquireAdConsent);

  if (!showConsent) {
    dispatch(clearUserData([ACCOUNT_USER_GDPR_CONSENTS_INQUIRY]));
    return;
  }
  // create consent types array with consent text id
  const consentTypes = !!gdprConsentsInquiry && Object.keys(gdprConsentsInquiry)
    .filter(key => [CONSENT_INQUIRE_DATA_TYPE_BEW, CONSENT_INQUIRE_DATA_TYPE_DATA].includes(key))
    .reduce((obj, key) => {
      if (gdprConsentsInquiry[key]) {
        obj.push(key === CONSENT_INQUIRE_DATA_TYPE_BEW ?
          FORM_FIELD_GDPR_CONSENT_BEW : FORM_FIELD_GDPR_CONSENT_DAT);
      }
      return obj;
    }, []);

  // filter consent texts by inquired consent types
  const currentConsents = consentTexts ? consentTexts.filter((c) =>
    consentTypes ? consentTypes.some(v => c.id.includes(v)) : []) : [];
  if (!currentConsents.length) return;

  // get used text hashes
  const consentTextHashes = currentConsents.reduce((acc, next) => {
    return {
      ...acc,
      ...next.id === FORM_FIELD_GDPR_CONSENT_BEW && { adConsentTextHash: next.hash },
      ...next.id === FORM_FIELD_GDPR_CONSENT_DAT && { passDataTextHash: next.hash },
    };
  }, {});

  const requestBody = {
    method: REQUEST_METHOD_POST,
    payload: {
      adConsent: gdprConsentsInquiry.inquireAdConsent || adConsent.adConsent,
      passData: gdprConsentsInquiry.inquirePassData || adConsent.passData,
      ...consentTextHashes,
    },
  };

  const actions = [
    {
      label: ui.selfcareConsentLayerSubmit,
      action: () => {
        dispatch(registryActions.fetchGdprConsentsAggregatedData(requestBody));
        dispatch(hideQueuedDialog());
        dispatch(clearUserData([ACCOUNT_USER_GDPR_CONSENTS_INQUIRY]));
      },
    },
    {
      label: ui.selfcareConsentLayerSkip,
      action: () => {
        dispatch(hideQueuedDialog());
        dispatch(clearUserData([ACCOUNT_USER_GDPR_CONSENTS_INQUIRY]));
      },
    },
  ];

  const dialogConfig = {
    id: 'ConsentInquiryDialog',
    component: ConsentInquiryDialog,
    theme: 'consentinquiry',
    props: {
      headline: ui.selfcareConsentLayerHeadline,
      subline: ui.selfcareConsentLayerSubline,
      consents: currentConsents,
    },
    actions,
    withCloseAction: false,
    isExpanded: true,
    withoutLightboxFadeout: false,
    shouldCloseOnTransition: true,
  };
  dispatch(addExtensiveDialogToQueue(dialogConfig));
};

export const showSimArticleListDialog = () => async (dispatch, getState) => {
  await dispatch(registryActions.fetchUiElements([UI_ESIM_DEVICE_CHECK]));
  await dispatch(registryActions.fetchArticleInformationGroups());

  const { ui } = getState();

  const dialogConfig = {
    id: 'SimArticleListDialog',
    theme: 'simarticlelist',
    title: ui.esimDeviceCheckHeadline,
    props: {
      headline: ui.esimDeviceCheckHeadline,
      withoutContainer: true,
    },
    component: ESimDeviceCheck,
    onClose: () => dispatch(hideDialog()),
    withCloseAction: true,
  };

  dispatch(showDialog(dialogConfig));
};

export const showFriendReferralHistoryDialog = () => async (dispatch, getState) => {
  const { ui } = getState();

  const actions = [
    {
      label: ui.guiWordAllright,
      withoutArrow: true,
      action: () => {
        dispatch(hideDialog());
      },
    },
  ];

  const dialogConfig = {
    id: 'FriendReferralHistoryDialog',
    component: FriendReferralHistoryDialog,
    props: {
      actions,
    },
    isExpanded: true,
  };
  dispatch(showDialog(dialogConfig));
};
