import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as msisdnStep from './formConfigMsisdn';
import * as smsStep from './formConfigSms';
import * as passwordStep from './formConfigPassword';
import * as optionsSelectStep from './formConfigOptionsSelect';
import FormManager from '../../form/FormManager';
import GlobalSection from '../../../components/basics/global/GlobalSection';
import { receivedCredentials } from '../../../actions/user/login';
import { send } from '../../../actions/request/send';
import LoginRequest from '../../../model/requests/LoginRequest';
import PasswordResetRequest from '../../../model/requests/PasswordResetRequest';
import { showNotification } from '../../../actions/page/dialog';
import { trackMyState } from '../../../actions/tracking/event';
import {
  QUERY_PASSW_RESET_TOKEN,
  MYTRACK_NOTIFY_PW_CHANGE_SUCCESS,
  MYTRACK_NOTIFY_PASSWORD_MISSMATCH,
  INLINE_ERROR_VALUE_EQUALITY_MISMATCH,
  FORM_FIELD_PASSWORD_CONFIRM,
} from '../../../helpers/constants';

const steps = [msisdnStep, optionsSelectStep, smsStep, passwordStep];

const emailTokenHistory = [
  msisdnStep.id,
  passwordStep.id,
];

const PasswordResetForm = (props) => {
  const { dispatch, location, sitemap, ui } = props;
  const token = location.query[QUERY_PASSW_RESET_TOKEN];
  const history = token ? emailTokenHistory : null;

  /**
   * Either the user entered a token via form input or the user
   * arrived at the form via email, in which case we extract the token
   * from the url.
   */
  const onBeforeSubmit = (fieldMap, normValues) =>
    (normValues[fieldMap.token.name] ? normValues : { ...normValues, token });

  /**
   * If the user chose the "reset via sms" option we are able to
   * log him/her in as we still have access to the msisdn that was
   * entered in the form.
   */
  const onAfterSubmitSuccess = async ({ responseBody, formValues }) => {
    const msisdn = formValues.msisdn || responseBody.msisdn;
    const password = formValues.password;
    dispatch(showNotification(ui.myPasswordEditSuccess));
    dispatch(trackMyState(MYTRACK_NOTIFY_PW_CHANGE_SUCCESS));
    try {
      const response = await dispatch(send(new LoginRequest(msisdn, password)));
      await dispatch(receivedCredentials(response));
    } catch (e) {
      // should not happen, but if it does, we should ignore it so we are still leaving the form
    }
  };

  const getSubmitRoute = (fieldMap, finalizedFormValues) =>
    new PasswordResetRequest(finalizedFormValues);

  /**
   * Track failing password equality check
   * @param errors
   */
  const onSubmitWillFail = (errors = {}) => {
    // @todo this needs work
    const { code } = errors[FORM_FIELD_PASSWORD_CONFIRM] || {};
    if (code === INLINE_ERROR_VALUE_EQUALITY_MISMATCH) {
      dispatch(trackMyState(MYTRACK_NOTIFY_PASSWORD_MISSMATCH));
    }
  };

  return (
    <GlobalSection>
      <FormManager
        form={PasswordResetForm.formName}
        location={props.location}
        submitRoute={getSubmitRoute}
        successRoute={sitemap.MyDashboardRoute.url}
        onAfterSubmitSuccess={onAfterSubmitSuccess}
        onSubmitWillFail={onSubmitWillFail}
        onBeforeSubmit={onBeforeSubmit}
        steps={steps}
        stepProps={props}
        history={history}
        withBlockError
        withFooter
      />
    </GlobalSection>
  );
};

/**
 * required by tracking!
 */
PasswordResetForm.formName = 'passwordReset';

PasswordResetForm.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    myPasswordEditSuccess: PropTypes.string.isRequired,
    myPasswordLostHeadline: PropTypes.string.isRequired,
  }),
  sitemap: PropTypes.object.isRequired,
};


const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = state => ({
  sitemap: state.site.sitemap,
  ui: state.ui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetForm);
