import { push } from 'react-router-redux';
import { bindQueryParams, locationToUrl } from '../../helpers/url';
import { ACTION_PREFIX, QUERY_INTERNAL_ID } from '../../helpers/constants';
import { showDialog } from './dialog';
import { fetchMyPromotion } from '../request/registry';
import { getAddConsentDialog } from '../dialog/misc';
import { isAppView } from '../../helpers/site';


export const ARRAY_DELIMITER = '|';
export const LOCATION_CHANGE_PENDING = `${ACTION_PREFIX}/LOCATION_CHANGE_PENDING`;
export const LOCATION_CHANGE_REQUESTED = `${ACTION_PREFIX}/LOCATION_CHANGE_REQUESTED`;
export const LOCATION_ADD_PROMPT = `${ACTION_PREFIX}/LOCATION_ADD_PROMPT`;
export const LOCATION_RESET_PROMPT = `${ACTION_PREFIX}/LOCATION_RESET_PROMPT`;

export const resetLocationChangePrompt = () => (dispatch) => (
  dispatch({
    type: LOCATION_RESET_PROMPT,
    payload: {},
  })
);

export const addLocationChangePrompt = ({ dialog, whitelist, withRedirect }) =>
  (dispatch, getState) => {
    dispatch({
      type: LOCATION_ADD_PROMPT,
      payload: {
        location: getState().routing.locationBeforeTransitions,
        dialog,
        whitelist,
        withRedirect,
      },
    });
  };

export const showLocationChangePrompt = (requestedLocation) => (dispatch, getState) => {
  const { site, routing } = getState();
  if (!site.locationPrompt) return;
  const dialogConfig = site.locationPrompt.dialog;
  if (site.locationPrompt.withRedirect) {
    const onClose = () => dispatch(push(locationToUrl(requestedLocation)));
    dialogConfig.onClose = onClose;
    dialogConfig.props.onClose = onClose;
  }
  if (dialogConfig.props.content) {
    // replace encoded keys with urls
    dialogConfig.props.content = dialogConfig.props.content.replace('%7BREQUESTED_URL%7D', locationToUrl(requestedLocation));
    dialogConfig.props.content = dialogConfig.props.content.replace('%7BCURRENT_URL%7D', locationToUrl(routing.locationBeforeTransitions));
  }
  dispatch(showDialog(dialogConfig));
  dispatch(resetLocationChangePrompt());
};

export const locationChangeBlockedByPrompt = (requestedLocation) => (dispatch, getState) => {
  const { site } = getState();
  const whitelist = (site.locationPrompt && site.locationPrompt.whitelist) || [];
  if (site.locationPrompt && !whitelist.some(url => requestedLocation.pathname === url)) {
    return true;
  }
  return false;
};

export const addLocationPrompts = (requestedLocation) => async (dispatch, getState) => {
  const { site, user } = getState();
  switch (requestedLocation.pathname) {
    case site.sitemap.MyPromotionDetailsRoute.url: {
      const iid = requestedLocation.query[QUERY_INTERNAL_ID];

      if (iid && !isAppView(site, requestedLocation) && user.credentials.msisdn) {
        try {
          const entity = await dispatch(fetchMyPromotion(iid));
          const dialog = await dispatch(getAddConsentDialog(entity, requestedLocation));
          if (dialog) {
            dispatch(addLocationChangePrompt({ dialog, withRedirect: false }));
          }
        } catch (e) {
          Error(e);
        }
      }
      break;
    }
    default:
      break;
  }
};

/**
 * Dispatched if a location change has been detected, e.g. because
 * a user clicked on a link or we did a push.
 * @param {Location} location
 */
export const requestedLocation = location => ({
  type: LOCATION_CHANGE_REQUESTED,
  payload: { location },
});

export const pendingLocation = location => ({
  type: LOCATION_CHANGE_PENDING,
  payload: { location },
});

export const updateQuery = (location, params) => {
  // try to get location from store if it is not defined
  if (!location) {
    return async (dispatch, getState) => (
      dispatch(updateQuery(getState().routing.locationBeforeTransitions, params))
    );
  }

  const url = location.pathname;
  // convert values that are arrays into strings for usage in the url
  const nextQueries = Object.keys(params).reduce(
    (next, name) => Object.assign({}, next, {
      [name]: Array.isArray(next[name]) ?
        next[name].join(ARRAY_DELIMITER) :
        next[name],
    }),
    params,
  );

  return push(
    bindQueryParams(
      url,
      Object.assign({}, location.query, nextQueries),
    ),
  );
};
